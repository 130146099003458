












































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class Agreement extends Vue {
    @Prop({ default: false }) isAgree?: boolean

    stausChange() {
        this.$emit('statusChange', !this.isAgree)
    }
}
